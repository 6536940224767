import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'

function VideoPage() {

    const fleetData = useLocation()
  return (
    <>   
     <Header fleetName={true} fleetData={fleetData}/>

     <div className='Dashboard_page_content'> 
     <div className='Video_page_wrapper'>
     
     {fleetData && fleetData.state?.data?.robots?.map((item)=>{ 
        return( 
            <div className='Video_page_wrapper_div'> 
            <p className='Video_page_wrapper_p'> {item.robotId}</p>
             <iframe className="Video_stream_iframe"autoControls={true} src={item?.viewerurl} allowFullScreen></iframe>
           </div>
        )
     })}
    </div>
    </div>
    </>
  )
}

export default VideoPage
