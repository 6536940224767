import axios from "axios";
import { fleetConstants } from "../constants/fleet";

export const fleetListAndRobotStatusByUserEmail =
    (email) => async (dispatch) => {
        try {
            dispatch({
                type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_REQUEST,
            });

            const token = localStorage.getItem("token");

            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListAndRobotStatusByUserEmail`,
                {
                    emailId: email,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (data.data.status === 200) {
                // console.log("data.data", data.data)
                let markers = [];
                data.data.fleet[0].robots.forEach((robotDetails) => {
                    if (parseFloat(robotDetails.latitude) !== 0 && parseFloat(robotDetails.longitude) !== 0) {
                        markers.push({
                            position: {
                                lat: parseFloat(robotDetails.latitude),
                                lng: parseFloat(robotDetails.longitude),
                            },
                        });
                    }
                });
                dispatch({
                    type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_SUCCESS,
                    payload: {
                        fleetData: data.data.fleet,
                        markers: markers
                    },
                });
            } else throw Error("Unable to fetch Fleet List. Something went wrong!");
        } catch (error) {
            dispatch({
                type: fleetConstants.FLEET_LIST_AND_ROBOT_STATUS_BY_USER_EMAIL_FAIL,
                payload: error,
            });
        }
    };

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: fleetConstants.CLEAR_ERRORS,
    });
};
