import React, { useCallback, useEffect, useRef } from 'react'
import { fleetListAndRobotStatusByUserEmail } from '../redux/actions/fleet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

function Dashboard() {

    const { fleetList } = useSelector(state => state.fleetList)
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const emailId = localStorage.getItem("useremail");
    const updateState = useCallback(async () => {
        dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }, [dispatch, emailId])
  const navigate =useNavigate()
    const useIntervalAsync = (callbackFunction, intervalInMs) => {
        const timeout = useRef();

        const apiCall = useCallback(async () => {
            await callbackFunction();
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
            timeout.current = window.setTimeout(apiCall, intervalInMs);
        }, [callbackFunction, intervalInMs])

        useEffect(() => {
            apiCall();
            return () => {
                clearTimeout(timeout.current);
            };
        }, [apiCall]);
    };

    useIntervalAsync(updateState, 5000)

    const handleFleetClick = (fleetList) => {
        navigate(`/videopage`, {
            state: { data: fleetList },
        });
    };
    let activerobot = 0;
  return ( 
    <>
      <Header/>
       <div className="container-fluid Dashboard_page_wrapper">
         <div className="row">
           {fleetList && fleetList?.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={"fleetList" + index}
                                                            className="col-md-4"
                                                            onClick={() => handleFleetClick(item)}
                                                        >
                                                            <div className="Dasboard_page_individual_fleet_box_wrapper">
                                                                <h4 className='Dashboard_page_fleet_heading'>
                                                                    {" "}
                                                                    {item.fleetName}
                                                                </h4>
                                                                {/* <div className="Dasboard_page_individual_fleet_box_inner_div"> <p> Client </p><p>{item.clientName}</p> </div> */}
                                                                <div className="Dasboard_page_individual_fleet_box_inner_div">
                                                                    {" "}
                                                                    <p> Location </p>
                                                                    <p>{item.location}</p>{" "}
                                                                </div>
                                                                <div className="Dasboard_page_individual_fleet_box_inner_div">
                                                                    {" "}
                                                                    <p> Robots </p>
                                                                    <p>{item.robots.length}</p>{" "}
                                                                </div>
                                                                <div className="Dasboard_page_individual_fleet_box_inner_div">
                                                                    {" "}
                                                                    <p> Coordinates </p>
                                                                    <p>
                                                                        {parseFloat(item.latitude).toFixed(2)} ,
                                                                        {parseFloat(item.longitude).toFixed(2)}
                                                                    </p>{" "}
                                                                </div>
                                                               
                                                            </div>
                                                            <div className='Dashboard_page_viewstream_button'>
                                                                <button> <img src="/assets/images/video-stream.svg"/>View Stream</button>
                                                                </div>
                                                        </div>
                                                    );
                                                })}
         </div>
      
      </div>
      </>
  )
}

export default Dashboard
