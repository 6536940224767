import React from 'react'
import LogoutModal from './LogoutModal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
function Header(props) {

    const firstName =localStorage.getItem('firstname')
    const lastName = localStorage.getItem('lastname')
    const role =localStorage.getItem('role')
    const navigate =useNavigate()
  return (
    <div className="Header_wrapper">
         <img src="/assets/images/OttonomyLogo.png"/>
         <div className='Header_wrapper_inner_div'>
         {props.fleetName ? <h4 className='Dashboard_page_fleet_heading header_h4'>
             <ArrowBackIcon onClick={()=>{navigate(-1)}} className="Header_arrow"/>
         {props.fleetData.state?.data.fleetName}   </h4> :null}  
            <div> 
            <p className="SideBar_name_p">{firstName} {lastName}</p>
            <p
              className="SideBar_name_p"
              style={{ opacity: "0.7", fontSize: "11px" }}
            >             {role}
            </p>
            </div>
            <LogoutModal />
          </div>
    </div>
  )
}

export default Header
