export const userConstants = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",

    LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
    LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
    LOAD_USER_FAIL: "LOAD_USER_FAIL",

    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAIL: "LOGOUT_FAIL",

    CLEAR_ERRORS: "CLEAR_ERRORS",
};
